<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">
      <template v-if="dataId">
        {{ originalData.name }} Bağımsız Bölüm Tipini Düzenle
        <v-col class="text-end">
          <v-btn
            class="me-2"
            color="pink"
            outlined
            @click="showActionLogs"
            v-if="dataId"
            icon
            small
          >
            İ
          </v-btn>
        </v-col>
      </template>
      <template v-else>Bağımsız Bölüm Tipi Ekle</template>
      <ActionLogList ref="actionLogList" />
    </template>
    <template slot="body">
      <v-form @submit.prevent="handleFormSubmit" ref="form">
        <v-row dense>
          <v-col sm="4">
            <rs-text-field
              :label="$t('labels.house_type')"
              :rules="[rules.required, rules.maxLength(formData.name, 300)]"
              v-model="formData.name"
            />
          </v-col>
          <v-col sm="4">
            <rs-text-field
              :label="$t('labels.area')"
              v-model="formData.area"
              type="number"
              min="0"
              step="0.01"
              max="99999.99"
              suffix="m²"
            />
          </v-col>
          <v-col sm="4">
            <rs-money-field
              v-model="formData.fee"
              :rules="[
                rules.min(formData.fee, 0.01),
                rules.max(formData.fee, 9999999.99),
              ]"
              :label="$t('labels.fee_amount')"
            />
          </v-col>
        </v-row>

        <p v-if="dataId" class="mt-5">
          {{ $t("labels.house_count") }}: {{ originalData.house_count }}
        </p>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide"
          @submit="handleFormSubmit"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ActionLogList from "@/view/content/action-logs/ActionLogList";
import hasForm from "@/view/mixins/hasForm";

export default {
  name: "HouseTypeForm",
  mixins: [hasForm],
  components: {
    ActionLogList,
  },
  props: {
    extraParams: {
      default: () => {},
      required: false,
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["clusterId"]),
  },
  watch: {
    dataId(newValue) {
      if (newValue) {
        this.load();
      }
    },
  },
  data() {
    return {
      formData: {
        name: null,
        area: null,
        fee: null,
      },
    };
  },
  methods: {
    ...mapActions(["reloadCluster"]),
    show(id) {
      this.dataId = id || null;

      this.formData = {
        name: null,
        area: null,
        fee: null,
      };

      this.$nextTick(() => {
        this.$refs.sidePanel.show();

        if (this.dataId) {
          this.load();
        }
      });
    },
    handleFormSubmit(event) {
      if (!this.$refs.form.validate() || this.isLoading) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      if (this.dataId) {
        this.$api
          .put(`house-types/${this.dataId}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved", response.data.data);
            this.reloadCluster(this.clusterId);
            if (event && event.closeOnSave) {
              this.hide();
              return;
            }

            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`clusters/${this.clusterId}/house-types`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.$nextTick(() => this.$emit("saved", response.data.data));
            this.reloadCluster(this.clusterId);
            if (event && event.closeOnSave) {
              this.hide();
              return;
            }

            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    load() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      this.$api
        .get("house-types", this.dataId)
        .then((response) => {
          this.loadData(response);
          document.getElementsByClassName("formatted-text")[0].innerHTML =
            this.numberToLocaleFormat(this.formData.fee);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    showActionLogs() {
      this.$refs.actionLogList.show("App\\Models\\HouseType", this.dataId);
    },
  },
};
</script>
