<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">
      <template v-if="dataId">
        {{ originalData.name }} Bloğunu Düzenle
        <v-col class="text-end">
          <v-btn
            class="me-2"
            color="pink"
            outlined
            @click="showActionLogs"
            v-if="dataId"
            icon
            small
          >
            İ
          </v-btn>
        </v-col>
      </template>
      <template v-else>Blok Ekle</template>
      <ActionLogList ref="actionLogList" />
    </template>
    <template slot="body">
      <v-form @submit.prevent="handleFormSubmit" ref="form">
        <v-row dense>
          <v-col sm="3">
            <rs-text-field
              :label="$t('labels.block_name')"
              :rules="[rules.required, rules.maxLength(formData.name, 300)]"
              v-model="formData.name"
            />
          </v-col>
          <v-col sm="3">
            <rs-text-field
              v-if="dataId"
              type="number"
              :label="$t('labels.house_count')"
              :value="originalData.house_count"
              readonly
              disabled
            />
          </v-col>
          <v-col sm="3">
            <rs-is-active
              v-model="formData.is_active"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide"
          @submit="handleFormSubmit"
        />
      </v-form>

      <v-divider class="my-5" />

      <SimpleHouseList
        v-if="dataId"
        :hide-title-bar="false"
        :block-id="dataId"
      />
    </template>
  </rs-side-panel>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ActionLogList from "@/view/content/action-logs/ActionLogList";
import ClusterService from "@/core/services/cluster.service";
import hasForm from "@/view/mixins/hasForm";
import SimpleHouseList from "./SimpleHouseList";

export default {
  name: "HouseBlockForm",
  mixins: [hasForm],
  components: {
    ActionLogList,
    SimpleHouseList,
  },
  props: {
    extraParams: {
      default: () => {},
      required: false,
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["clusterId"]),
  },
  watch: {
    dataId(newValue) {
      if (newValue) {
        this.load();
      }
    },
  },
  data() {
    return {
      isLoading: false,
      originalData: {},
      formData: {
        name: null,
        is_active: true,
      },
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    show(id) {
      this.dataId = id || null;

      this.formData = {
        name: null,
        is_active: true,
      };

      this.$nextTick(() => {
        this.$refs.sidePanel.show();

        if (this.dataId) {
          this.load();
        }
      });
    },
    handleFormSubmit(event) {
      if (!this.$refs.form.validate() || this.isLoading) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      if (this.dataId) {
        this.$api
          .put(`blocks/${this.dataId}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            ClusterService.loadBlocks();
            this.$emit("saved", response.data.data);
            this.updateClusterId(this.clusterId);
            if (event && event.closeOnSave) {
              this.hide();
              return;
            }

            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`clusters/${this.clusterId}/blocks`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            ClusterService.loadBlocks();
            this.$nextTick(() => this.$emit("saved", response.data.data));
            this.updateClusterId(this.clusterId);
            if (event && event.closeOnSave) {
              this.hide();
              return;
            }

            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    load() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      this.$api
        .get("blocks", this.dataId)
        .then((response) => {
          this.loadData(response);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    showActionLogs() {
      this.$refs.actionLogList.show("App\\Models\\Block", this.dataId);
    },
  },
};
</script>
